export const info = {
   
    college: "College of Engineering, Trivandrum, Kerala",
    address: "Calicut, Kerala, India",
    linkedin: "https://www.linkedin.com/company/eetic/",
    github: "https://www.github.com/",
    email: "contact@eetifoundation.org",
    phone: "https://wa.me/+919746577467",
    group: "https://chat.whatsapp.com/LFxXV0QWFgd330aSbZcEqY",
    website: "https://eetifoundation.org",
    medium: " ",
    twitter: " ",
    facebook: " ",
    instagram: "https://www.instagram.com/eeti_foundation/",
    youtube: "https://www.youtube.com/channel/UCN0dejvTCQ088J3Dd_Y0-Sg",
   
  };